<h2>{{ data.title }}</h2>
@if (data.message) {
  <p>{{ data.message }}</p>
}
@if (data.content) {
  <ng-container
    [ngTemplateOutlet]="data.content"
    [ngTemplateOutletContext]="{ $implicit: data.customData }"
  ></ng-container>
}
@if (data.spinner) {
  <p style="text-align: center">
    <app-spinner [loading]="true" [size]="64"></app-spinner>
  </p>
} @else {
  <app-button (click)="close($event)">{{ buttonLabel }}</app-button>
}
