import { Component, Inject, TemplateRef } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DIALOG_DATA, DialogRef } from "@angular/cdk/dialog";
import { ButtonComponent } from "../button/button.component";
import { SpinnerComponent } from "../spinner/spinner.component";

@Component({
  selector: "app-message-dialog",
  standalone: true,
  templateUrl: "./message-dialog.component.html",
  styleUrls: ["./message-dialog.component.scss"],
  imports: [CommonModule, ButtonComponent, SpinnerComponent],
})
export class MessageDialogComponent {
  buttonLabel: string;

  constructor(
    @Inject(DIALOG_DATA) public data: DialogData,
    private dialogRef: DialogRef
  ) {
    this.buttonLabel = data.buttonLabel ?? "Rozumím";
    if (data.spinner) {
      dialogRef.disableClose = true;
    }
  }

  close = (event: MouseEvent) => {
    event.preventDefault();
    this.dialogRef.close();
  };
}

export interface DialogData {
  title: string;
  message: string;
  buttonLabel?: string;
  content?: TemplateRef<any>;
  customData?: any;
  spinner?: boolean;
}
