import { Injectable, signal, WritableSignal } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import {
  doc,
  docData,
  DocumentReference,
  Firestore,
} from "@angular/fire/firestore";
import { IUser, UserType } from "src/models/User";
import { IAuthProvider } from "src/models/Auth";
import {
  Auth,
  AuthProvider,
  ConfirmationResult,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  onAuthStateChanged,
  RecaptchaVerifier,
  sendSignInLinkToEmail,
  signInWithPhoneNumber,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  User,
  UserCredential,
} from "@angular/fire/auth";
import { ActivatedRoute, Router } from "@angular/router";
import { configureScope, captureException } from "@sentry/angular-ivy";
import { Dialog } from "@angular/cdk/dialog";
import { MessageDialogComponent } from "src/app/components/message-dialog/message-dialog.component";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public $loggedIn = new BehaviorSubject<boolean | undefined>(undefined);
  public loggedIn: WritableSignal<boolean | undefined> = signal(undefined);
  public role: WritableSignal<UserType | null | undefined> = signal(undefined);
  public $firebaseUser = new BehaviorSubject<User | undefined | null>(
    undefined
  );
  public $user = new BehaviorSubject<IUser | undefined | null>(undefined);
  private userDocSubscription!: Subscription;
  public userDocRef!: DocumentReference<IUser>;
  private redirectInterval: NodeJS.Timeout | undefined;

  constructor(
    private auth: Auth,
    private firestore: Firestore,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialog: Dialog
  ) {
    onAuthStateChanged(this.auth, async (firebaseUser) => {
      this.userDocSubscription?.unsubscribe();

      if (firebaseUser !== null) {
        // user is signed in
        this.$firebaseUser.next(firebaseUser);
        this.$loggedIn.next(true);
        this.loggedIn.set(true);

        this.userDocRef = doc(
          this.firestore,
          `users/${firebaseUser.uid}`
        ) as DocumentReference<IUser>;
        this.userDocSubscription = docData(this.userDocRef, {
          idField: "id",
        }).subscribe((user) => {
          // user data is loaded from DB
          this.$user.next(user);
        });

        // save role
        this.role.set(await this.getRole(true));

        // Sentry asscoiate email
        configureScope((scope) => {
          scope.setUser({ email: firebaseUser.email ?? undefined });
        });

        const returnUrl = this.activatedRoute.snapshot.queryParams["returnUrl"];
        if (this.router.url.split("?")[0] === "/prihlasit" && returnUrl) {
          // redirect if 'returnUrl' was in URL query
          const navigate = await this.router.navigateByUrl(returnUrl);
          if (!navigate) {
            this.handleFailedNavigation(returnUrl);
          }
        } else if (this.router.url.split("?")[0] === "/prihlasit") {
          // redirect to home, if the route is 'login' and there is no 'returnUrl'
          this.router.navigateByUrl("/");
        }
      } else {
        // user is signed out
        this.$loggedIn.next(false);
        this.loggedIn.set(false);
        this.$firebaseUser.next(null);
        this.$user.next(null);
        this.role.set(null);

        /* if (this.router.url !== '/') {
          this.router.navigateByUrl('/');
        } */
      }
    });
  }

  /**
   * If redirection to a new route failed, try to re-do it later.
   *
   * @param url URL path to try to redirect to
   * @param iteration Number of current retry iteration (should start on 0).
   * @param limit Limit of maximum reties. After that the system stops trying and logs and exception.
   */
  private handleFailedNavigation(url: string, iteration = 0, limit = 15) {
    if (iteration === 0) {
      this.dialog.open(MessageDialogComponent, {
        data: {
          title: "Počkejte prosím…",
          message: "Přesměrováváme vás k cílové destinaci.",
          spinner: true,
        },
      });
    }

    this.redirectInterval = setInterval(async () => {
      const navigate = await this.router.navigateByUrl(url);
      if (navigate) {
        clearInterval(this.redirectInterval);
      } else if (limit <= iteration) {
        clearInterval(this.redirectInterval);
        captureException(`Failed to redirect to '${url}' in ${limit} tries.`);
      } else {
        this.handleFailedNavigation(url, iteration + 1, limit);
      }
    }, 1000);
  }

  public async phoneSignInPrompt(
    message: string,
    recaptchaVerifier: RecaptchaVerifier
  ): Promise<boolean> {
    try {
      const phoneNumber = window.prompt(message);

      if (phoneNumber) {
        // register new account
        const confirmationResult = await signInWithPhoneNumber(
          this.auth,
          phoneNumber,
          recaptchaVerifier
        );

        // verify phone code
        const credentials = await this.verifyPhoneCode(
          "Zadejte ověřovací kód, který Vám přišel v SMS",
          confirmationResult
        );

        return credentials ? true : false;
      }

      return false;
    } catch (error: any) {
      if (error?.code === "auth/invalid-phone-number") {
        return await this.phoneSignInPrompt(
          "Zadejte číslo ve správném formátu, včetně mezinárodní předvolby (+420).",
          recaptchaVerifier
        );
      } else {
        captureException(error);
        return false;
      }
    }
  }

  private async verifyPhoneCode(
    message: string,
    confirmationResult: ConfirmationResult
  ): Promise<UserCredential | null> {
    try {
      const verificationCode = window.prompt(message);
      return await confirmationResult.confirm(verificationCode as string);
    } catch (error: any) {
      if (error?.code === "auth/invalid-verification-code") {
        return await this.verifyPhoneCode(
          "Ověřovací kód nesedí. Zkuste to prosím znovu.",
          confirmationResult
        );
      } else {
        captureException(error);
        return null;
      }
    }
  }

  public async popupSignIn(provider: IAuthProvider): Promise<boolean> {
    try {
      // select Provider
      let authProvider: AuthProvider;
      switch (provider) {
        case IAuthProvider["google.com"]:
          authProvider = new GoogleAuthProvider();
          break;
        case IAuthProvider["facebook.com"]:
          authProvider = new FacebookAuthProvider();
          break;
        case IAuthProvider["apple.com"]:
          authProvider = new OAuthProvider("apple.com");
          break;

        default:
          throw new Error(`Unsupported Auth Provider: ${provider}`);
      }

      // sign-in user
      await signInWithPopup(this.auth, authProvider);
    } catch (error) {
      captureException(error);
      return false;
    }

    return true;
  }

  public async redirectSignIn(provider: IAuthProvider): Promise<boolean> {
    try {
      // select Provider
      let authProvider: AuthProvider;
      switch (provider) {
        case IAuthProvider["google.com"]:
          authProvider = new GoogleAuthProvider();
          break;
        case IAuthProvider["facebook.com"]:
          authProvider = new FacebookAuthProvider();
          break;
        case IAuthProvider["apple.com"]:
          authProvider = new OAuthProvider("apple.com");
          break;

        default:
          throw new Error(`Unsupported Auth Provider: ${provider}`);
      }

      // sign-in user
      await signInWithRedirect(this.auth, authProvider);
    } catch (error) {
      captureException(error);
      return false;
    }

    return true;
  }

  public async emailLinkSignIn(emailAddress: string, redirectPath = "") {
    await sendSignInLinkToEmail(this.auth, emailAddress, {
      url: window.location.origin + "/email-auth?returnUrl=" + redirectPath,
      handleCodeInApp: true,
    });

    window.localStorage.setItem("emailForSignIn", emailAddress);
  }

  public async getUser(): Promise<IUser | null> {
    return this.$user.value !== undefined
      ? this.$user.value
      : new Promise((resolve) => {
          const sub = this.$user.subscribe((user) => {
            if (user) {
              sub.unsubscribe();
              resolve(user);
            }
          });
        });
  }

  public async getUserLoggedIn(): Promise<IUser | null> {
    return (
      this.$user.value ??
      new Promise((resolve) => {
        const sub = this.$user.subscribe((user) => {
          if (user) {
            sub.unsubscribe();
            resolve(user);
          }
        });
      })
    );
  }

  public async getLoggedIn(): Promise<boolean> {
    return this.$loggedIn.value !== undefined
      ? this.$loggedIn.value
      : new Promise((resolve) => {
          const sub = this.$loggedIn.subscribe((loggedIn) => {
            if (loggedIn !== undefined) {
              sub.unsubscribe();
              resolve(loggedIn);
            }
          });
        });
  }

  /**
   * Returns authenticated User's role from the Auth claim.
   *
   * @param forceRefresh Force refresh Auth Token regardless of token expiration.
   */
  public async getRole(forceRefresh = false): Promise<UserType | null> {
    if (this.$firebaseUser.value) {
      return (await this.$firebaseUser.value.getIdTokenResult(forceRefresh))
        .claims["role"] as UserType | null;
    } else if (this.$firebaseUser.value === undefined) {
      return new Promise((resolve) => {
        const sub = this.$firebaseUser.subscribe((firebaseUser) => {
          if (firebaseUser !== undefined) {
            sub.unsubscribe();
            firebaseUser?.getIdTokenResult(forceRefresh).then((token) => {
              resolve(token.claims["role"] as UserType | null);
            });
          }
        });
      });
    }

    return null;
  }

  /**
   * Checks the current user has the specified role.
   *
   * @param roles UserType role that should current have.
   */
  public async hasRole(
    roles: UserType | UserType[] | undefined
  ): Promise<boolean> {
    if (!roles) {
      // no role required, pass any user
      return true;
    }

    let userRole = await this.getRole();
    if (!userRole) {
      // if no role was returned, force refresh the auth token
      userRole = await this.getRole(true);
    }

    if (typeof roles === "object") {
      // array of roles
      return roles.find((role: UserType) => role === userRole) !== undefined;
    }

    // role given as string
    return userRole === roles;
  }

  /**
   * Updates or creates user in db after login
   */
  /* private async updateUserAfrerLogin(user: User): Promise<void> {
    // update existing user data
    const updateUser = {
      username: user.displayName,
      email: user.email,
      profile_picture: user.photoURL,
    };

    try {
      // try to update existing user
      return await this.userDoc.update(updateUser);
    } catch {
      // create new user in db
      return this.userDoc.set(newUser);
    }
  } */

  public async signOut(): Promise<boolean> {
    this.$loggedIn.next(false);
    this.loggedIn.set(false);
    this.$firebaseUser.next(null);
    this.$user.next(null);
    this.userDocSubscription?.unsubscribe();

    // sign out
    await signOut(this.auth);

    // revalidate route
    return await this.router.navigateByUrl(this.router.url);
  }
}
